<template>
	<modal ref="refModal" titulo="Números de guias del pedido" tamano="modal-sm" cerrar noAceptar no-cancelar no-adicional>
		<div class="row justify-content-center">
			<div class="">
				<div v-for="(c, key) in guias" :key="key" class="d-middle-bt my-3">
					<div class="d-middle">
						<p class="f-16 text-black2 w-120px tres-puntos">{{ c.numero_guia }}</p>
						<el-tooltip effect="light" content="Rastear pedido">
							<a :href="c.url_guia" target="_blank" class="btn-go-to">
								<i class="icon-go-to text-general-red f-20" @click="redirect(c.url_guia)" />
							</a>
						</el-tooltip>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {

	data() {
		return {
			guias: []
		}
	},
	methods: {
		toggle(items) {
			this.guias = items
			this.$refs.refModal.toggle();
		},
		redirect(route) {
			window.open(route, '_blank');
		},
	}
}
</script>
